// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-hvordan-oppforer-vi-oss-js": () => import("./../../../src/pages/hvordan-oppforer-vi-oss.js" /* webpackChunkName: "component---src-pages-hvordan-oppforer-vi-oss-js" */),
  "component---src-pages-hvordan-ser-vi-ut-js": () => import("./../../../src/pages/hvordan-ser-vi-ut.js" /* webpackChunkName: "component---src-pages-hvordan-ser-vi-ut-js" */),
  "component---src-pages-hvordan-vi-snakker-js": () => import("./../../../src/pages/hvordan-vi-snakker.js" /* webpackChunkName: "component---src-pages-hvordan-vi-snakker-js" */),
  "component---src-pages-hvorfor-utfordrer-vi-js": () => import("./../../../src/pages/hvorfor-utfordrer-vi.js" /* webpackChunkName: "component---src-pages-hvorfor-utfordrer-vi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

